@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Hubballi&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: Hubballi, system-ui, sans-serif;
    }
}
.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
audio {
    filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
    width: 20vw;
    height: 25px;
}

#spinner {

    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
}

@keyframes rotate {
    to {
        transform: rotate(3600deg);
    }
}